
  import { computed, defineComponent, ref, reactive, getCurrentInstance } from "vue"
  import { extendProviderAgencyBillSegment, agencyBillKeyData, billVariantIndex, ranks } from "@/utils/billHelper"
  import { generateYearSelectOptions, generateMonthSelectOptions, round, stringSort, shortDate } from "@/utils/helper"
  import { ResourceObject } from '@/models/jsonapi'
  import { Segment } from "@/models/segments"
  import { AgencyBill as AgencyBillModel } from "@/models/agency_bills"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { segmentSchema, providerUiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import KeyValue from "@/components/shared/KeyValue.vue"
  import BillKeyData from "@/components/shared/BillKeyData.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import { alertDialog } from "@/plugins/alert"
  import { fullName } from "@/utils/dataExtractors"

  export default defineComponent({
    components: {
      KeyValue,
      BillKeyData,
      SchemaForm,
      FileMetaData
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const initialLoadingDone = ref(false)
      const year = ref<number>(new Date().getFullYear())
      const month = ref<number>(new Date().getMonth())
      const yearOptions = generateYearSelectOptions()
      const monthOptions = generateMonthSelectOptions()
      const billIndex = reactive<{[key:string]: ResourceObject<string, AgencyBillModel>}>({})
      const segmentIndex = reactive<{[key: string]: ResourceObject<string, Segment>}>({})
      const cid2Row = reactive<{[key: string]: any}>({})
      const pdfIndex = reactive<{[key: string]: any}>({})
      const selectedBill = ref<any>(null)
      const segmentToEdit = ref<any>(null)
      const editSegmentModal = ref()
      const extSegmentIndex = ref({})
      const freeSegments = ref([])

      const requestData = async () => {
        showLoading.value = true
        const params = {year: year.value, month: month.value}
        return root.$store.dispatch('agency_bills/bill_data', params)
          .then((response) => {
            initialLoadingDone.value = true
            billIndex.value = response.billIndex
            segmentIndex.value = response.segmentIndex
            cid2Row.value = response.cid2Row
            pdfIndex.value = response.pdfIndex
            calculateBillData()
            selectedBill.value = null
            extSegmentIndex.value = calculateExtSegmentIndex()
            gatherSelectedBillKeyData()
          })
          .finally(()=>{
            showLoading.value = false
            if (bills.value?.length>0) toggleSelectedBill(bills.value[0])
          })
      }
      requestData()

      const pdf = computed(() => {
        if (!initialLoadingDone.value) return []
        if (selectedBill.value.id) {
          const pdf = pdfIndex.value[selectedBill.value.id]
          return pdf ? [pdf] : []
        } else {return []}
      })


      const calculateExtSegmentIndex = () => {
        if (!initialLoadingDone.value) return {}
        const reducer = (res:any,seg:ResourceObject<string, Segment>) => {
          extendProviderAgencyBillSegment(seg)
          const contractId = seg.attributes?.contractId
          if (contractId) seg.meta = {...cid2Row.value[contractId], ...seg.meta}
          if (seg.id) res[seg.id] = seg
          return res
        }
        return Object.values(segmentIndex.value).reduce(reducer, {})
      }
      // const extSegmentIndex = computed(()=>{
      //   console.log('esi')
      //   return calculateExtSegmentIndex()
      // })

      const bid2Segs = computed(() => {
        if (!initialLoadingDone.value) return {free:[]}
        const result = {free: []} as {free: ResourceObject<string, Segment>[]}
        const indexer = (seg: ResourceObject<string, Segment>) => {
          const abId = seg.attributes?.agencyBillId
          if (abId) {
            result[abId] ? result[abId].push(seg) : result[abId] = [seg]
          } else {
            result.free.push(seg)
          }
        }
        Object.values(extSegmentIndex.value).forEach(indexer)
        return result
      })

      const billKeyData = ref({})
      const gatherSelectedBillKeyData = () => {
        if (!selectedBill.value) return {}
        const segs = bid2Segs.value[selectedBill.value.id]
        billKeyData.value = agencyBillKeyData(selectedBill.value, segs, 'crName')
      }

      const bills = ref<any[]>([])
      const calculateBillData = () => {
        if (!initialLoadingDone.value) return []
        bills.value = stringSort(Object.values(billIndex.value), (e) => e.attributes.billNr)
      }

      const segments = computed(() => {
        if (!initialLoadingDone.value || !selectedBill.value?.id) return []
        return stringSort(bid2Segs.value[selectedBill.value.id], (s) => s.meta.crLastname)
      })

      const getFreeSegments = () => {
        const params = {
          'filter[client_paid]': false,
          'filter[billable]': true,
          'filter[contract_id.ne]': 'null',
          'fields[segments]': 'all',
          'fields[agency_bills]': 'bill_nr,presented,reviewed,paid,entries,billDate,month,year',
          'fields[addresses]': 'first_name,last_name',
          'fields[care_givers]': 'first_name,last_name',
          'fields[file_meta_datas]': 'filename,length,updated_at,mail_included,contentType',
          'include': 'contract.household.care_receivers.address,contract.household.lead.address,agency_bill,care_giver_instance.care_giver',
        }
        root.$store.dispatch('segments/list_v2', {'filter[client_paid]': false, 'filter[contract_id.ne]': 'null'}).then((response) => {
          // console.log('count response: ', response)
          params['page[size]'] = response.meta.totalItems
          root.$store.dispatch('segments/list_v2', params)
            .then((response) => {
              const tmpIndex = response.included.reduce((res:any, entry:any) => {
                res[entry.id] = entry
                return res
              }, {})
              freeSegments.value = response.data.map((seg:any) => {
                const con = tmpIndex[seg.relationships.contract.data.id]
                const hh = tmpIndex[con.relationships.household.data.id]
                const lead = tmpIndex[hh.relationships.lead.data.id]
                const leadAdd = tmpIndex[lead.relationships.address.data.id]
                const crs = hh.relationships.careReceivers.data.map((cr) => { return tmpIndex[cr.id] })
                const crAdds = crs.map((cr) => tmpIndex[cr.relationships.address.data.id])
                const cgi = tmpIndex[seg.relationships.careGiverInstance.data.id]
                const cg = tmpIndex[cgi.relationships.careGiver.data.id]
                // console.log('con: ',con, 'hh: ',hh, 'lead: ',lead, 'leadAdd: ',leadAdd,'crs: ',crs,'crAdds: ',crAdds,'ag: ',ag,'cgi: ',cgi, 'cg: ',cg)
                return {
                  segment: seg,
                  contract: con,
                  cgName: fullName(cg),
                  leadName: fullName(leadAdd),
                  crNames: crAdds.map((e) => fullName(e)).join(', '),
                }
              })
            })
        })
      }
      getFreeSegments()

      const toggleSelectedBill = (data: any) => {
        const cond = selectedBill.value?.id === data.id
        selectedBill.value = cond ? null : data
        gatherSelectedBillKeyData()
      }

      const updateSelectedBill = (data: any) => {
        showLoading.value = true
        root.$store.dispatch('agency_bills/edit', {id: selectedBill.value.id, body: data})
          .finally(()=>{
            showLoading.value = false
            requestData()
            calculateBillData()
            gatherSelectedBillKeyData()
          })
      }

      const toExcel = () => {
        const output = segments.value.map((s) => {
          return [
            s.meta.crFirstname[0],
            s.meta.crLastname[0],
            s.meta.provision.toString().replace('.',','),
            shortDate(s.attributes.agStart),
            shortDate(s.attributes.agEnd),
            s.meta.duration,
            s.meta.provisionProportional.toString().replace('.',',')
          ].join(';')
        }).join("\n")
        copyToClipboard(output)
        return
      }

      const copyToClipboard = (text:string) => {
        var el = document.createElement('textarea')
        el.value = text
        // Set non-editable to avoid focus and move outside of view
        // el.style = {position: 'absolute', left: '-9999px'}
        el.setAttribute('readonly', '')
        document.body.appendChild(el)
        // Select text inside element
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        prompt('Ins clipboard kopiert!')
      }

      const prompt = (msg:string, type='success') => {
        alertDialog.variant.value = type
        alertDialog.message.value = msg
        alertDialog.show.value = true
      }

      const sendUsecase = (usecase: string) => {
        updateSelectedBill({usecase})
      }

      const billButtonVariant = (bill: any) => ranks[billVariantIndex(bill)]

      const freeColumns = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "contract.id",
          label: "Vertrag",
          sortable: true,
          options: {
            target: '_blank',
            hrefFunction: (data:any) => {
              return `/contracts/${data.item.contract.id}`
            },
            contentFunction: (data:any) => {
              return data.item.crNames || 'Kein Name'
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "cgName",
          label: "BK Name",
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "leadName",
          label: "Kunde",
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "segment.attributes.agStart",
          label: "Start",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.start !== atts.agStart) ? 'text-warning' : ''
            }
          }
        },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "segment.attributes.start",
        //   label: "Start PfS",
        //   sortable: true,
        //   options: {
        //     type: 'date',
        //     cellClassFunction: (data: any) => {
        //       const atts = data.item.attributes
        //       return (atts && atts.start !== atts.agStart) ? 'text-warning' : ''
        //     }
        //   }
        // },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "segment.attributes.agEnd",
          label: "Ende",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.end !== atts.agEnd) ? 'text-warning' : ''
            }
          }
        },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.end",
        //   label: "Ende PfS",
        //   sortable: true,
        //   options: {
        //     type: 'date',
        //     cellClassFunction: (data: any) => {
        //       const atts = data.item.attributes
        //       return (atts && atts.end !== atts.agEnd) ? 'text-warning' : ''
        //     }
        //   }
        // },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "segment.attributes.provAgencyTotal",
          label: "Prov.",
          sortable: true,
          options: {
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              if (atts && atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
                return 'text-warning'
              } else { return '' }
            }
          }
        },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.provPfs",
        //   label: "Prov. PfS",
        //   sortable: true,
        //   options: {
        //     contentFunction: (data: any) => {
        //       const atts = data.item.attributes
        //       return atts ? atts.provSeller+atts.provPfs : "NA"
        //     },
        //     cellClassFunction: (data: any) => {
        //       const atts = data.item.attributes
        //       if (atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
        //         return 'text-warning'
        //       } else { return '' }
        //     }
        //   }
        // },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "segment.attributes.discountCg",
          label: "Monatsrabatt",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "segment.attributes.note",
          label: "Notiz",
        },
        {
          component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
          key: "move",
          label: "",
          options: {
            buttons: [
              // {
              //   action: (data: any) => swapSegment(data.item),
              //   icon: ['fad', 'random'],
              // },
              {
                action: (data: any) => {
                  segmentToEdit.value = data.item.segment
                  editSegmentModal.value.show()
                },
                title: 'Abschnitt bearbeiten',
                icon: ['fas', 'pencil-alt'],
              }
            ]
          }
        },
      ]

      const columns = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "meta.crLastname",
          label: "Pflegebedürftige",
          sortable: true,
          options: {
            target: '_blank',
            hrefFunction: (data:any) => {
              return `/contracts/${data.item.attributes.contractId}`
            },
            contentFunction: (data:any) => {
              return data.item.meta.crName || 'Kein Name'
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.agStart",
          label: "Start",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.start !== atts.agStart) ? 'text-warning' : ''
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.start",
          label: "Start PfS",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.start !== atts.agStart) ? 'text-warning' : ''
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.agEnd",
          label: "Ende",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.end !== atts.agEnd) ? 'text-warning' : ''
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.end",
          label: "Ende PfS",
          sortable: true,
          options: {
            type: 'date',
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              return (atts && atts.end !== atts.agEnd) ? 'text-warning' : ''
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.provAgencyTotal",
          label: "Prov.",
          sortable: true,
          options: {
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              if (atts && atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
                return 'text-warning'
              } else { return '' }
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.provPfs",
          label: "Prov. PfS",
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const atts = data.item.attributes
              return atts ? atts.provSeller+atts.provPfs : "NA"
            },
            cellClassFunction: (data: any) => {
              const atts = data.item.attributes
              if (atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
                return 'text-warning'
              } else { return '' }
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.discountCg",
          label: "Monatsrabatt",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "meta.duration",
          label: "Tage",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              return data.item.meta.duration
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "meta.provisionProportional",
          label: "Prov. prop.",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.note",
          label: "Notiz",
        },
        {
          component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
          key: "move",
          label: "",
          options: {
            buttons: [
              // {
              //   action: (data: any) => swapSegment(data.item),
              //   icon: ['fad', 'random'],
              // },
              {
                action: (data: any) => {
                  segmentToEdit.value = data.item
                  editSegmentModal.value.show()
                },
                title: 'Abschnitt bearbeiten',
                icon: ['fas', 'pencil-alt'],
              }
            ]
          }
        },
      ]

      const saveSegment = async (bvModalEvent: any) => {
        showLoading.value =true
        const segment = segmentToEdit.value
        root.$store.dispatch('segments/edit', { id: segment.id, body: {attributes: segment.attributes}})
          .then((r) => {
            calculateExtSegmentIndex()
            gatherSelectedBillKeyData()
            getFreeSegments()
          })
          .finally(() => {
            showLoading.value = false
            root.$nextTick(() => editSegmentModal.value.hide())
          })
      }

      const billButtonTitle = (bill: any) => {
        if (bill.attributes?.presented && bill.attributes?.reviewed) {
          return bill.attributes.billNr
        } else {
          return 'Vorläufige Rechnung'
        }
      }

      const segOutput = (segs:any) => {
        return segs.map((s)=>{
          return [s.id, s.meta.provisionProportional].join(':')
        }).join(',')
      }

      return {
        showLoading,
        year,
        yearOptions,
        month,
        monthOptions,
        requestData,
        selectedBill,
        toggleSelectedBill,
        updateSelectedBill,
        extSegmentIndex,
        bills,
        billKeyData,
        toExcel,
        sendUsecase,
        segments,
        freeSegments,
        columns,
        editSegmentModal,
        segmentToEdit,
        saveSegment,
        segmentSchema,
        providerUiSegmentSchema,
        pdf,
        billButtonVariant,
        segmentIndex,
        billButtonTitle,
        freeColumns,
        segOutput
      }
    }
  })
